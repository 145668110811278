import { gql } from "@apollo/client";

// TODO: resource ogData.title changes after connections modal
const CORE_METADATA_FIELDS = gql`
  fragment CoreMetadataFields on Metadata {
    title
    description
    ogData {
      title
      url
      description
      determiner
      locale
      localeAlternate
      siteName
    }
  }
`;

export const CORE_RESOURCE_FIELDS = gql`
  ${CORE_METADATA_FIELDS}
  fragment CoreResourceFields on Resource {
    category
    audiobook {
      title
      chapter
      author
      url
      metadata {
        ...CoreMetadataFields
      }
    }
    book {
      title
      chapter
      author
      url
      metadata {
        ...CoreMetadataFields
      }
    }
    podcastEpisode {
      name
      url
      metadata {
        ...CoreMetadataFields
      }
      podcast {
        name
        feedUrl
      }
    }
    social {
      author
      platform
      url
      metadata {
        ...CoreMetadataFields
      }
    }
    website {
      url
      metadata {
        ...CoreMetadataFields
      }
    }
    video {
      title
      siteName
      url
      metadata {
        ...CoreMetadataFields
      }
    }
  }
`;

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    firstName
    lastName
    name
    email
  }
`;

export const CORE_USER_FIELDS_WITHOUT_EMAIL = gql`
  fragment CoreUserFieldsWithoutEmail on User {
    id
    firstName
    lastName
    name
  }
`;

// TODO: remove this fragment
export const CORE_SPARK_FIELDS = gql`
  ${CORE_RESOURCE_FIELDS}
  ${CORE_USER_FIELDS_WITHOUT_EMAIL}
  fragment CoreSparkFields on Spark {
    id
    notes
    shared
    createdAt
    media {
      id
      url
    }
    connections {
      id
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        connections {
          id
          sparks {
            id
            clusters {
              id
              shared
              user {
                id
              }
            }
            user {
              id
            }
          }
        }
        createdAt
        clusters {
          id
          shared
          user {
            id
          }
          sparks {
            id
          }
        }
        logs {
          id
          type
          cluster {
            id
          }
          sparks {
            id
          }
        }
        user {
          id
        }
      }
      createdAt
      updatedAt
    }
    clusters {
      id
      name
      description
      shared
      sparks {
        id
      }
      followers {
        id
      }
      logs {
        id
        type
        cluster {
          id
          sparks {
            id
          }
        }
        sparks {
          id
        }
      }
      user {
        ...CoreUserFieldsWithoutEmail
      }
      createdAt
      updatedAt
    }
    resource {
      ...CoreResourceFields
    }
    logs {
      id
      type
      cluster {
        id
      }
      sparks {
        id
      }
    }
    user {
      ...CoreUserFieldsWithoutEmail
    }
  }
`;

const CORE_CLUSTER_FIELDS = gql`
  fragment CoreClusterFields on Cluster {
    id
    name
    description
    sparks {
      id
    }
    shared
    followers {
      id
    }
    logs {
      # should logs be included as core? used to wrap cluster in shared by sparks
      id
      type
      sparks {
        id
      }
      cluster {
        id
      }
    }
    createdAt
    updatedAt
    user {
      id
    }
  }
`;

export const CORE_LOG_FIELDS = gql`
  ${CORE_SPARK_FIELDS}
  ${CORE_CLUSTER_FIELDS}
  ${CORE_USER_FIELDS}
  fragment CoreLogFields on Log {
    id
    type
    reason
    updatedUser
    sparks {
      ...CoreSparkFields
    }
    cluster {
      ...CoreClusterFields
    }
    processedFor {
      ...CoreUserFields
    }
    processedBy {
      ...CoreUserFields
    }
    createdAt
    updatedAt
  }
`;
