import { gql } from "@apollo/client";
import {
  CORE_SPARK_FIELDS,
  CORE_LOG_FIELDS,
  CORE_USER_FIELDS,
  CORE_RESOURCE_FIELDS,
  CORE_USER_FIELDS_WITHOUT_EMAIL,
} from "./fragments";

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    currentUser {
      id
      firstName
      lastName
      name
      email
      type
      role
    }
  }
`;

export const GET_SPARKS_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_SPARKS_QUERY($input: SparksInput) {
    sparks(input: $input) {
      sparks {
        id
        notes
        createdAt
        resource {
          ...CoreResourceFields
        }
        media {
          id
        }
        connections {
          id
          sparks {
            id
            clusters {
              id
              shared
            }
            user {
              id
            }
          }
        }
        clusters {
          id
          shared
          user {
            id
          }
        }
        logs {
          id
        }
      }
      total
    }
  }
`;

export const GET_ALL_SPARK_IDS_QUERY = gql`
  query GET_ALL_SPARK_IDS_QUERY {
    allSparkIds {
      ids
    }
  }
`;

export const SEARCH_SPARKS_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  ${CORE_USER_FIELDS_WITHOUT_EMAIL}
  query SEARCH_SPARKS_QUERY($input: SearchSparksInput) {
    searchSparks(input: $input) {
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        user {
          ...CoreUserFieldsWithoutEmail
        }
        createdAt
      }
      total
    }
  }
`;

export const SEARCH_SPARKS_META_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  ${CORE_USER_FIELDS_WITHOUT_EMAIL}
  query SEARCH_SPARKS_QUERY($input: SearchSparksMetaInput) {
    searchSparks(input: $input) {
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        user {
          ...CoreUserFieldsWithoutEmail
        }
        createdAt
      }
      total
    }
  }
`;

export const GET_SPARKS_TO_ADD_TO_CLUSTER_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_SPARKS_TO_ADD_TO_CLUSTER_QUERY($input: SparksToAddToClusterInput) {
    sparksToAddToCluster(input: $input) {
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        createdAt
        user {
          id
          name
          firstName
          lastName
        }
      }
      total
    }
  }
`;

export const GET_SHARED_SPARKS_TO_ADD_TO_CLUSTER_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_SPARKS_TO_ADD_TO_CLUSTER_QUERY($input: SparksToAddToClusterInput) {
    sharedSparksToAddToCluster(input: $input) {
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        createdAt
      }
      total
    }
  }
`;

export const GET_SPARKS_TO_CONNECT_TO_SPARK_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_SPARKS_TO_CONNECT_TO_SPARK_QUERY(
    $input: SparksToConnectToSparkInput
  ) {
    sparksToConnectToSpark(input: $input) {
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        user {
          id
          name
          firstName
          lastName
        }
        createdAt
      }
      total
    }
  }
`;

export const GET_SPARK_QUERY = gql`
  ${CORE_SPARK_FIELDS}
  query GET_SPARK_QUERY($input: SparkInput) {
    spark(input: $input) {
      ...CoreSparkFields
    }
  }
`;

export const GET_RELATED_SPARKS_QUERY = gql`
  ${CORE_SPARK_FIELDS}
  query GET_RELATED_SPARKS_QUERY($input: RelatedSparksInput) {
    relatedSparks(input: $input) {
      sparks {
        ...CoreSparkFields
      }
    }
  }
`;

export const GET_RELATED_SPARKS_COUNT_QUERY = gql`
  query GET_RELATED_SPARKS_COUNT_QUERY($input: RelatedSparksInput) {
    relatedSparksCount(input: $input) {
      count
    }
  }
`;

export const GET_CLUSTERS_QUERY = gql`
  query GET_CLUSTERS_QUERY($input: ClustersInput) {
    clusters(input: $input) {
      clusters {
        id
        name
        description
        sparks {
          id
        }
        shared
        followers {
          id
        }
        logs {
          id
          type
          sparks {
            id
          }
          cluster {
            id
          }
        }
        createdAt
        updatedAt
        user {
          id
          firstName
          name
        }
      }
      total
    }
  }
`;

export const SEARCH_CLUSTERS_QUERY = gql`
  query SEARCH_CLUSTERS_QUERY($input: SearchClustersInput) {
    searchClusters(input: $input) {
      clusters {
        id
        name
        sparks {
          id
        }
      }
      total
    }
  }
`;

export const GET_CLUSTERS_TO_ADD_TO_SPARK_QUERY = gql`
  query GET_CLUSTERS_TO_ADD_TO_SPARK_QUERY($input: ClustersToAddToSparkInput) {
    clustersToAddToSpark(input: $input) {
      clusters {
        id
        name
        sparks {
          id
        }
      }
      total
    }
  }
`;

export const GET_CLUSTER_QUERY = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_CLUSTER_QUERY($input: ClusterInput) {
    cluster(input: $input) {
      id
      name
      description
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        connections {
          id
          sparks {
            id
            clusters {
              id
              shared
            }
            user {
              id
            }
          }
        }
        clusters {
          id
          shared
          user {
            id
          }
        }
        logs {
          id
        }
        user {
          id
          firstName
          name
        }
        createdAt
      }
      shared
      followers {
        id
      }
      logs {
        id
        type
        sparks {
          id
        }
        cluster {
          id
        }
      }
      createdAt
      updatedAt
      user {
        id
        firstName
        name
      }
    }
  }
`;

export const GET_CLUSTER_QUERY_RAW = gql`
  ${CORE_RESOURCE_FIELDS}
  query GET_CLUSTER_QUERY_RAW($input: ClusterInput) {
    clusterRaw(input: $input) {
      id
      name
      description
      sparks {
        id
        notes
        resource {
          ...CoreResourceFields
        }
        connections {
          id
          sparks {
            id
            user {
              id
            }
          }
        }
        clusters {
          id
          shared
          user {
            id
          }
        }
        logs {
          id
        }
        user {
          id
          firstName
          name
        }
        createdAt
      }
      shared
      followers {
        id
      }
      logs {
        id
        type
        sparks {
          id
        }
        cluster {
          id
        }
      }
      createdAt
      updatedAt
      user {
        id
        firstName
        name
      }
    }
  }
`;

export const GET_LOGS_QUERY = gql`
  ${CORE_LOG_FIELDS}
  query GET_LOGS_QUERY($input: LogsInput) {
    logs(input: $input) {
      logs {
        ...CoreLogFields
      }
      total
    }
  }
`;

export const GET_USERS_QUERY = gql`
  ${CORE_USER_FIELDS}
  query GET_USERS_QUERY($input: UsersInput) {
    users(input: $input) {
      users {
        ...CoreUserFields
      }
      total
    }
  }
`;

export const GET_SUBSCRIPTION_QUERY = gql`
  query GET_SUBSCRIPTION_QUERY($input: SubscriptionInput) {
    subscription(input: $input) {
      id
      type
      user {
        id
      }
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation SIGN_UP_MUTATION($input: SignUpInput) {
    signUp(input: $input) {
      user {
        id
        role
        email
        firstName
        lastName
        name
        type
      }
      token
    }
  }
`;

export const GOOGLE_SIGN_UP_MUTATION = gql`
  mutation GOOGLE_SIGN_UP_MUTATION($input: GoogleSignUpInput) {
    googleSignUp(input: $input) {
      user {
        id
        role
        email
        firstName
        lastName
        name
        type
      }
      token
    }
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation SIGN_IN_MUTATION($input: SignInInput) {
    signIn(input: $input) {
      user {
        id
        role
        email
        firstName
        lastName
        name
        type
      }
      token
    }
  }
`;

export const GOOGLE_SIGN_IN_MUTATION = gql`
  mutation GOOGLE_SIGN_IN_MUTATION($input: GoogleSignInInput) {
    googleSignIn(input: $input) {
      user {
        id
        role
        email
        firstName
        lastName
        name
        type
      }
      token
      newUserData {
        firstName
        lastName
        email
      }
    }
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION($input: SignOutInput) {
    signOut(input: $input) {
      message
    }
  }
`;

export const CREATE_SPARK_MUTATION = gql`
  mutation CREATE_SPARK_MUTATION($input: CreateSparkInput) {
    createSpark(input: $input) {
      id
    }
  }
`;

export const UPDATE_SPARK_MUTATION = gql`
  mutation UPDATE_SPARK_MUTATION($input: UpdateSparkInput) {
    updateSpark(input: $input) {
      id
    }
  }
`;

export const DELETE_SPARK_MUTATION = gql`
  mutation DELETE_SPARK_MUTATION($input: DeleteInput) {
    deleteSpark(input: $input) {
      id
    }
  }
`;

export const CREATE_CLUSTER_MUTATION = gql`
  mutation CREATE_CLUSTER_MUTATION($input: CreateClusterInput) {
    createCluster(input: $input) {
      id
    }
  }
`;
export const UPDATE_CLUSTER_MUTATION = gql`
  mutation UPDATE_CLUSTER_MUTATION($input: UpdateClusterInput) {
    updateCluster(input: $input) {
      id
    }
  }
`;

export const DELETE_CLUSTER_MUTATION = gql`
  mutation DELETE_CLUSTER_MUTATION($input: DeleteInput) {
    deleteCluster(input: $input) {
      id
    }
  }
`;

export const DELETE_MEDIA_MUTATION = gql`
  mutation DELETE_MEDIA_MUTATION($input: DeleteInput) {
    deleteMedia(input: $input) {
      id
    }
  }
`;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation REQUEST_RESET_PASSWORD_MUTATION($input: RequestResetPasswordInput) {
    requestResetPassword(input: $input) {
      message
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      message
    }
  }
`;

export const SEND_ANALYTICS_MUTATION = gql`
  mutation SEND_ANALYTICS_MUTATION($input: AnalyticsInput) {
    sendAnalytics(input: $input) {
      message
    }
  }
`;

export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
  mutation CREATE_CHECKOUT_SESSION_MUTATION {
    createCheckoutSession {
      id
    }
  }
`;

export const CREATE_CUSTOMER_PORTAL_SESSION_MUTATION = gql`
  mutation CREATE_CUSTOMER_PORTAL_SESSION_MUTATION {
    createCustomerPortalSession {
      id
    }
  }
`;

export const EXPIRE_FREE_TRIALS_MUTATION = gql`
  mutation EXPIRE_FREE_TRIALS_MUTATION {
    expireFreeTrials {
      message
    }
  }
`;
